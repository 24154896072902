<template>
  <b-modal
    id="view-Upload-modal"
    v-model="isModalVisible"
    :hide-footer="true"
    title="Upload Assignment"
    title-class="my-title"
    centered
    @hide="emitClose"
  >
    <div
      class="text-center px-2 py-2 bg-colorBgGray"
      style="border-radius: 20px"
    >
      <validation-observer ref="uploadAssignmentFormValidation">
        <b-form class="auth-login-form mt-2 px-2" @submit.prevent>
          <div class="text-center">
            <h3 class="text-colorBlack font-weight-bolder mb-1">Upload File</h3>
          </div>
          <b-row class="d-flex align-items-center">
            <b-col md="12">              
              <b-form-group label-for="file">
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    :state="errors.length > 0 ? false : null"
                    name="file"
                    placeholder="Click browse through your machine "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <div class="text-right pb-2 px-50">
      <b-button
        style="margin-top: 260px"
        block
        type="submit"
        variant="colorBlue"
        class="mr-1"
        rounded
        @click="submit()"        
      >
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    assignment: Object,
    modalVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      file: "",
      isModalVisible: false,
    };
  },
  watch: {
    modalVisible(value) {
      this.isModalVisible = value;
    },
  },
  methods: {
    ...mapActions({
      submitAssignment: "appData/submitAssignment",
    }),

    emitClose() {
      this.$emit("close");
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append("assignment", this.assignment.id);
        formData.append("user", this.getLoggedInUser.id);
        formData.append("file", this.file);
        alert("clicked");
        
        const res = await this.submitAssignment(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Assignment Submitted",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("view-Upload-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset(){
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-title {
  color: black;
  font-weight: bold;
}
</style>
