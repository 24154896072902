<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">
            {{ subject.name }} with {{ subject.teacher_data.name }}
          </h3>
          <p class="font-weight-bold text-colorGray mb-50">{{ subject.code }}</p>
        </b-col>
        <b-col md="4" class="px-3">
          <div
            class="pb-1 pt-50 px-2"
            style="border-radius: 20px; border: 2px solid lightgray"
          >
            <b-row class="d-flex align-items-center justify-content-end">
              <b-col md="9">
                <h6 class="font-weight-bolder text-colorBlack">
                  2/4 Assignment Completed
                </h6>
              </b-col>
              <b-col md="3"
                ><h2 class="font-weight-bolder text-colorBlack">50%</h2>
              </b-col>
            </b-row>
            <b-progress
              class="mt-2"
              :value="value"
              :max="max"
              show-progress
              animated
            ></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-2 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Assignments</span
                >
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Assignments
                  </h3>
                </b-col>
                <b-col md="4">
                  <div class="text-right">
                    <b-link :to="{ name: 'LectureDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector.svg"
                        img-fluid
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                    <b-link :to="{ name: 'OxarDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector-2.svg"
                        img-fluid
                        class="ml-1"
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsAssignment"
                  :items="assignments"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(name)="row">
                    <div class="py-3">
                      <span
                        @click="openModal"
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 1rem"
                      >
                        {{ row.item.name ? row.item.name : "" }}
                        <br />
                        <span class="font-weight-bold text-colorGray">
                          {{ row.item.DateTime ? row.item.DateTime : "" }}</span
                        >
                      </span>
                    </div>
                  </template>
                  <template #cell(deadline)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 1rem"
                    >
                      {{ row.item.deadline ? row.item.deadline : "" }}
                    </span>
                  </template>

                  <template #cell(submission_status)="row">
                    <b-button variant="colorOrange" pill size="sm" class="">
                      {{ row.item.submission_status == 'submitted' ? "Submitted" : "Not Submitted Yet" }}
                    </b-button>
                  </template>
                  <template #cell(manage)="row">
                    <b-button
                      variant="colorGray"
                      rounded
                      size="sm"
                      class="text-center ml-0 ml-md-4"
                      @click="openModalUpload(row.item)"
                      
                    >
                      Upload
                    </b-button>
                    <b-button
                      variant="colorBlue"
                      rounded
                      size="sm"
                      class="text-center ml-50"
                      @click="openModalFeedback"
                    >
                      View Feedback
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs></b-col
        >
      </b-row>
    </div>
    <ViewFeedbackModal
      :modalVisible="modalVisibleFeedback"
      @close="closeModalFeedback"
    />
    <AssignmentDetailModal :modalVisible="modalVisible" @close="closeModal" />
    <AssignmentUploadModal
      :modalVisible="modalVisibleUpload"
      @close="closeModalUpload"
      :assignment=assignment
    />
  </div>
</template>

<script>
import ViewFeedbackModal from "@/components/student/assignment/ViewFeedbackModal.vue";
import AssignmentDetailModal from "@/components/student/assignment/AssignmentDetailModal.vue";
import AssignmentUploadModal from "@/components/student/assignment/AssignmentUploadModal.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ViewFeedbackModal,
    AssignmentDetailModal,
    AssignmentUploadModal,
  },
  props: ['id'],
  data() {
    return {
      fieldsAssignment: [
        { key: "name", label: "Assignmnet" },
        { key: "deadline", label: "Deadline" },
        { key: "submission_status", label: "Status" },
        { key: "manage", label: "" },
      ],
      assignments: [],
      modalVisible: false,
      modalVisibleFeedback: false,
      modalVisibleUpload: false,
      max: 20,
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      subject: {},
      assignment: {},
      value: 0,
      total_assignments: 0,
    };
  },
  methods: {
    ...mapActions({
      getAssignments: "appData/getAssignments",
      getSubject: "appData/getSubject",
    }),

    async fetchPaginatedData() {
      try {
        const res_subject = await this.getSubject({pk: this.$route.query.id});
        this.subject = res_subject.data;

        const res = await this.getAssignments({
          pageNumber: this.currentPage,
          subject: this.subject.id,
        });
        this.assignments = res.data;  
        console.log(this.assignments); 
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },


    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    openModalFeedback() {
      this.modalVisibleFeedback = true;
    },
    closeModalFeedback() {
      this.modalVisibleFeedback = false;
    },
    openModalUpload(assignment) {
      this.modalVisibleUpload = true;
      this.assignment = assignment;
    },
    closeModalUpload() {
      this.modalVisibleUpload = false;
    },
  },

  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },

    "$route.query.id"(newId, oldId) {
      if (newId !== oldId) {
        this.fetchPaginatedData();
      }
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  
};
</script>

<style></style>
