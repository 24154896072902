<template>
  <b-modal
    id="view-feedback-modal"
    v-model="isModalVisible"
    :hide-footer="true"
    title="Teacher Feedback"
    title-class="my-title"
    centered
    @hide="emitClose"
  >
    <div
      class="text-center px-2 py-2 bg-colorBgGray"
      style="border-radius: 20px"
    >
      <h1 class="m-0 p-0 font-weight-bolder text-colorBlack">A</h1>
      <p class="m-0 mt-50 p-0 font-weight-bold text-colorBlack">Grade</p>
    </div>

    <div class="mt-2">
      <h3 class="font-weight-bolder text-colorBlack">Teacher Feedback</h3>
    </div>
    <div
      style="border-radius: 20px; border: 1px solid lightgray"
      class="font-weight-normal text-colorGray p-1 mt-1"
    >
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages
      </p>
    </div>

    <div class="mt-2">
      <h3 class="font-weight-bolder text-colorBlack">Attachments</h3>
    </div>
    <div
      style="border-radius: 20px; border: 1px solid lighgray"
      class="font-weight-normal text-colorGray"
    >
      <b-row
        class="mt-1 bg-colorBgGray pt-1 d-flex align-items-center"
        style="border-radius: 20px"
      >
        <b-col md="8">
          <p>
            <span
              class="font-weight-bolder text-colorBlack m-0"
              style="font-size: 0.8rem"
            >
              <img
                src="@/assets/images/icons/assignment/pdf.svg"
                img-fluid
                width="40px"
                height="40px"
                class="mr-50"
              />Report Name.pdf
            </span>
          </p></b-col
        >
        <b-col md="4" class="text-right pt-50" align-self="stretch">
          <img
            class="cursor-pointer"
            src="@/assets/images/icons/assignment/download.svg"
            img-fluid
            width="20px"
            height="20px"
          />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  watch: {
    modalVisible(value) {
      this.isModalVisible = value;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-title {
  color: black;
  font-weight: bold;
}
</style>
